import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://365f62b35c00c0cb9181bfc08e545713@sentry.rangteam.ru/7",

  tracesSampleRate: 0.5,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,

  integrations: [Sentry.replayIntegration()],

  debug: false,
  
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error.name.startsWith("ERR_NETWORK") ||
      error.message === 'Load failed'
    ) {
      return null
    }
    return event;
  },

});
